import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core'

@Component({
  selector: 'cft-teaser-card-title, h3[cft-teaser-card-title]',
  templateUrl: 'teaser-card-title.component.html',
  styles: `
      :host {
        @apply block;
        @apply mb-xs;
      }
      :host(:last-child) {
        @apply mb-0;
      }
      :host-context(.cft-teaser-card--hero) {
         @apply md:text-2xl text-xl
      }
    `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TeaserCardTitleComponent {
  @HostBinding('class')
  className = 'heading-md'
}
