import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {NgOptimizedImage} from '@angular/common'
import {I18nDirective} from '../i18n/i18n.directive'

@Component({
  selector: 'a[cft-author], cft-author',
  templateUrl: './author.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, I18nDirective],
  styleUrls: ['./author.component.css'],
})
export class AuthorComponent {
  @Input({required: true}) author!: Author
}

export interface Author {
  name: string
  portrait?: {
    url: string
  }
}
