import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {NgOptimizedImage} from '@angular/common'
import {AuthorComponent} from '../../author/author.component'
import {ChipComponent} from '../../chip/chip.component'

@Component({
  selector: 'a[cft-teaser-card], cft-teaser-card',
  templateUrl: './teaser-card.component.html',
  styleUrls: ['./teaser-card.component.css'],
  imports: [NgOptimizedImage, AuthorComponent, ChipComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TeaserCardComponent {
  @Input({required: true})
  image!: ImageAsset
}

interface ImageAsset {
  url: string
  title?: string
}
