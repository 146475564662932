<div class="teaser-card__container group">
  <div class="teaser-card__image-container">
    @if (image) {
      <img [ngSrc]="image.url" class="object-cover" [alt]="image.title || ''" fill />
    }
    <div class="absolute left-xs top-xs md:left-md md:top-md">
      <ng-content select="cft-chip, [cft-chip]"></ng-content>
    </div>
  </div>
  <div class="teaser-card__content">
    <ng-content select="cft-teaser-card-plot, [cft-teaser-card-plot]" />
    <ng-content select="cft-teaser-card-title, [cft-teaser-card-title]" />
    <ng-content />
    <ng-content select="cft-author" />
  </div>
</div>
